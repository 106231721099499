import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination,Navigation,Autoplay } from "swiper/modules";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // Star Icons
import Image3 from './../../assets/img/testimonial/1.png'
import Image4 from './../../assets/img/testimonial/2.png'
import Image2 from './../../assets/img/testimonial/3.png'
import Image6 from './../../assets/img/testimonial/image5.png'
import Image7 from './../../assets/img/testimonial/image11.png'
import Image1 from './../../assets/img/testimonial/Image1.png'

import "./Testimonials.css"; // Import the CSS file
import 'swiper/css/navigation';

const testimonials = [
  {
    id: 1, name: "Jatin Yadav", text: `
Orenda Finserv has made transactions incredibly smooth with their Zero Touch Payout Portal. The user-friendly interface and efficient business volume tracking features are impressive. Their system-generated top-up reminders help maintain seamless operations. Excellent customer support — quick and responsive! Highly recommended for businesses looking for reliable payout solutions.`, image: Image1, rating: 5
  },
  { id: 2, name: "Bansari (Credence Finance)", text: "I wanted to say a massive thank you for the support you and your teams have given me. I love that you've taken the time to speak to everyone individually, you've made a great impact in the corporate field and you are absolutely doing great work. BEST COORPORATE DSA I HAVE KNOW IN LAST 5 YEAR.....❤️❤️🔥🔥", image: Image2, rating: 5 },
  { id: 3, name: "Aman Patel", text: 'I am constantly impressed by your performance. Your commitment to innovation, seamless financial solutions, and unwavering support have made a significant impact. Thank you for supporting us Shobha madam and Sushma madam 👍👍', image:Image3, rating: 5 },
  { id: 4, name: "Rajesh Solanki", text: `Orenda Finserv LLP is more than just a funding provider—they are a true growth partner. Their expertise, flexible repayment solutions, and professional service make it easy for us to recommend them to clients. A fintech company that truly delivers value!`, image:Image4, rating: 5 },
  { id: 5, name: "Dharmesh Sedani", text: `Orenda Finserv LLP has been a game-changer for our business! Their tailored financial solutions and quick funding process helped us manage our working capital efficiently. The team is highly professional, transparent, and always ready to assist. Highly recommended for businesses looking for reliable financial support!`, image: Image6, rating: 5 },
  { id: 6, name: "Priyanka Marvania", text: `Exceptional service from Orenda Finserv LLP! Their expertise in unsecured funding and flexible repayment options made the entire process stress-free. The team is customer-focused, professional, and always ensures seamless execution.`, image: Image7, rating: 5 },

];
const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="star filled" />);
      } else if (i - 0.5 === rating) {
        stars.push(<FaStarHalfAlt key={i} className="star half" />);
      } else {
        stars.push(<FaRegStar key={i} className="star empty" />);
      }
    }
    return <div className="star-rating">{stars}</div>;
  };
const Testimonials = () => {
  return (
    <main className="root-section">
                  <h2 className="heading">Testimonials</h2>
    <section className="testimonial-section">

<Swiper
  effect="coverflow"
  grabCursor={true}
  centeredSlides={true}
  slidesPerView={5} /// Ensure only 5 are visible at a time
  loop={true}
  spaceBetween={-10} // Adjust slide spacing
  slidesPerGroup={1} // Move one at a time
  loopAdditionalSlides={2}  // ✅ Ensures equal slides on left and right
  autoplay={{
    delay: 2500,
    disableOnInteraction: false,
  }}  speed={800} // Smooth transition
  coverflowEffect={{
    rotate: 0,
    stretch: -10, // Brings slides closer
    depth: 350, // Adjusts depth perspective
    modifier: 1,
    slideShadows: true,
  }}
  breakpoints={{
    320: { slidesPerView: 1, spaceBetween: 0 },  // Mobile - 1 slide
    768: { slidesPerView: 3, spaceBetween: -10 },  // Tablets - 1 center, 1 each side
    1024: { slidesPerView: 3, spaceBetween: -10 }, // ✅ Full - 1 center, 2 each side
  }}
  navigation={{ clickable: true }} 
  pagination={{ clickable: true }}
  modules={[EffectCoverflow, Pagination,Navigation ,Autoplay]}
  className="testimonial-swiper"
>


        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id} className="testimonial-slide">
            <div className="testimonial-card">
              <img 
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image bg-white" height='128px' width='128px'
              />
                            <StarRating rating={testimonial.rating} />

                            <h4 className="testimonial-name">{testimonial.name}</h4>

              <p className="testimonial-text">{testimonial.text}</p>
              {/* <p className="testimonial-role">{testimonial.role}</p> */}

            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
    </main>

  );
};

export default Testimonials;
