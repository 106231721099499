import React from 'react';
import aboutimg from "../.././assets/img/about/image.png";
import vision from "../.././assets/img/about/vision.svg";
import mission from "../.././assets/img/about/mission.svg";
import "../.././App.css";
import './About.css';

const About = () => {
    return (  
      <main id="about" className="animated-bg-about">

        <section className="about-section">

          {/* 🔥 First Row (Image Left, Title & Vision/Mission Right) */}
          <div className="about-top-row">
            <div className="about-image-container">
              <img src={aboutimg} alt="About Us" />
            </div>

            <div className="about-right-content">
            <h2 className="about-heading">About Us</h2>

              <div className="vision-mission">

                <div className="vision">
                  <img src={vision} alt="Vision" className='icon' />
                  <div className="text-content">
                    <h3>Our Vision</h3>
                    <p>To become the leading marketplace for financial products, leveraging technology-driven pre-profiling to match customers with the most suitable lenders, ensuring seamless and optimized financing solutions.</p>
                  </div>
                </div>

                <div className="mission">
                  <img src={mission} alt="Mission" className='icon' />
                  <div className="text-content">
                    <h3>Our Mission</h3>
                    <p>We empower businesses and individuals by ensuring they get the most suitable financial solutions, matched seamlessly with lenders based on their needs and documentation.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 🔥 Second Row (Stats Left & Extra Content Right) */}
          <div className="about-bottom-row">
            
            {/* Stats Section (Left) */}
            <div className="stats-container">
              <div className="stats-card">
                <div className="stack stack-2"></div>
                <div className="stack stack-1"></div>
                <div className="content">
                  <h2> <span>50</span>+</h2>
                  <p>Lending Partners</p>
                </div> 
              </div>

              <div className="stats-card">
                <div className="stack stack-2"></div>
                <div className="stack stack-1"></div>
                <div className="content">
                  <h2><span>5000</span>+</h2>
                  <p>Channel Partners</p>
                </div> 
              </div>

              <div className="stats-card">
                <div className="stack stack-2"></div>
                <div className="stack stack-1"></div>
                <div className="content">
                  <h2><span>10,000 </span>Cr+</h2>
                  <p>Funds Disbursed</p>
                </div>  
              </div>
            </div>

            {/* Extra Content (Right) */}
            <div className="about-extra-content">
              <h2> Who We Are ?</h2>
              <p>
              Orenda Finserv LLP is a financial consultancy firm, led by a team of Chartered Accountants, dedicated to simplifying unsecured financing for MSMEs and salaried individuals. We specialize in matching borrowers with the most suitable lenders by analyzing their financial profiles and documentation. With a strong focus on operational efficiency, we leverage a structured approach to ensure fast, transparent, and optimized funding solutions. As we evolve, our vision is to build a cutting-edge financial marketplace where customers can seamlessly access a variety of financial products. By streamlining the lending process, we aim to bridge the gap between borrowers and lenders, fostering trust and financial growth.              </p>
            </div>

          </div>

        </section>
      </main>
    );
};

export default About;
